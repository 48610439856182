import { Target, TypedController, Value } from "@vytant/stimulus-decorators";
import { Controller } from "stimulus";

@TypedController
export default class extends Controller {
  @Target readonly passwordInputTarget: HTMLInputElement;
  @Target readonly submitButtonTarget!: HTMLButtonElement;

  connect() {
    this.passwordInputTarget.addEventListener("input", this.updateSubmitButtonVisability.bind(this));
  }

  updateSubmitButtonVisability() {
    const value = this.passwordInputTarget.value;
    if (value.length > 0) {
      this.submitButtonTarget.removeAttribute("disabled");
    } else {
      this.submitButtonTarget.setAttribute("disabled", "true");
    }
  }
}
