import { Target, Targets, TypedController, Value } from "@vytant/stimulus-decorators";
import { Controller } from "stimulus";

@TypedController
export default class extends Controller<HTMLFormElement> {
  @Targets readonly digitTargets: HTMLInputElement[];
  @Target readonly invalidCodeMessageTarget: HTMLElement;
  @Target readonly resendAgainButtonTarget: HTMLButtonElement;
  @Target readonly resendAgainTextTarget: HTMLElement;
  @Target readonly formTarget: HTMLFormElement;
  @Target readonly spinnerTarget: HTMLElement;
  @Target readonly remainginSecondsLockedMessageTarget: HTMLElement;

  @Value(Number) readonly digitCountValue: number = 4;
  @Value(Number) readonly resendAgainTimeValue: number = 30000;
  @Value(Number) secondsRemainingLockedValue = 1;

  connect() {
    if (this.secondsRemainingLockedValue > 0) {
      this.disabledInputsAndCountdown();
    }

    document.addEventListener("paste", this.codePasted.bind(this));

    setTimeout(() => {
      this.enableResend();
    }, this.resendAgainTimeValue);
  }

  disabledInputsAndCountdown() {
    this.removeInvalidCodeErrorMessage();
    this.setInputsDisabledAttributeTo(true);

    const interval = setInterval(() => {
      this.secondsRemainingLockedValue = this.secondsRemainingLockedValue - 1;

      if (this.secondsRemainingLockedValue <= 0) {
        clearInterval(interval);
        this.setInputsDisabledAttributeTo(false);
        this.digitTargets[0].focus();
        this.remainginSecondsLockedMessageTarget.classList.add("hidden");
      }

      const secondsSpan = this.remainginSecondsLockedMessageTarget.querySelector("#remaining-seconds");
      secondsSpan!.innerHTML = String(this.secondsRemainingLockedValue);
    }, 1000);
  }

  digitEntered(event: any) {
    const index = Number((event.target as HTMLElement).dataset.index);
    if (event.key === "Enter") {
      event.preventDefault();
      this.submit();

      return;
    }

    switch (event.key) {
      case "Backspace":
      case "Delete":
        event.preventDefault();

        // @ts-expect-error IDK WHY
        this.digitTargets[index].value = null;

        if (index > 0) {
          this.digitTargets[index - 1].focus();
        } else {
          this.digitTargets[index].focus();
        }
        return;
      case "ArrowLeft":
        event.preventDefault();

        if (index > 0) {
          this.digitTargets[index - 1].focus();
        }
        return;
      case "ArrowRight":
        event.preventDefault();

        if (index < this.digitCountValue - 1) {
          this.digitTargets[index + 1].focus();
        }
        return;
      default:
        if (!/^[0-9]$/i.test(event.key)) {
          return;
        }

        event.preventDefault();

        if (index < this.digitCountValue) {
          this.digitTargets[index].value = event.key;
        }

        if (index + 1 < this.digitCountValue) {
          this.digitTargets[index + 1].focus();
        }

        if (index + 1 === this.digitCountValue) {
          this.submit();
        }
    }

    this.removeInvalidCodeErrorMessage();
  }

  codePasted(event: ClipboardEvent) {
    event.preventDefault();

    if (this.secondsRemainingLockedValue > 0) {
      return;
    }

    this.removeInvalidCodeErrorMessage();
    const pastedCode = event.clipboardData?.getData("text").slice(0, this.digitCountValue) || "";
    for (let i = 0; i < this.digitCountValue; i++) {
      if (pastedCode[i] === undefined) {
        break;
      }

      if (!/^[0-9]$/i.test(pastedCode[i])) {
        continue;
      }

      this.digitTargets[i].value = pastedCode[i];
    }

    if (pastedCode.length === this.digitCountValue) {
      this.submit();
    }
  }

  removeInvalidCodeErrorMessage() {
    this.digitTargets.forEach((digitTarget) => {
      digitTarget.classList.remove("border-red-500");
    });

    this.invalidCodeMessageTarget.classList.add("hidden");
  }

  enableResend() {
    this.resendAgainButtonTarget.disabled = false;
    this.resendAgainTextTarget.classList.remove("opacity-40");
  }

  submit() {
    this.spinnerTarget.classList.remove("hidden");
    this.formTarget.submit();
    this.setInputsDisabledAttributeTo(false);
  }

  setInputsDisabledAttributeTo(state: boolean) {
    this.digitTargets.forEach((digitTarget) => {
      digitTarget.disabled = state;
    });
  }
}
